import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DecksGallery } from "./components/DecksGallery/DecksGallery";
import React, { useEffect, useState } from "react";
import { getDecks } from "../../shared/queries/deck.queries";

export function DecksPage () {
    const { t } = useTranslation()
    const history = useHistory();
    const [decks, setDecks] = useState([]);

    const init = async () => {
        try {
            console.log('##ABEL## >> DecksPage >>  init', 11);

            const { data: { data } } = await getDecks();
            // const resPersonal = await API.get('/decks?filter[scope]=personal&filter[idUser]=' + user.id);
            console.log('##ABEL## >> DecksPage >>  init', data);
            setDecks(data);
        } catch (e) {
            setDecks([]);
        }

    }

    const goToEdit = idDeck => {
        if (idDeck) {
            history.push('decks/' + idDeck + '/edit')
        } else {
            history.push('decks/add')

        }
    }

    useEffect(init, [])
    return (<div>
        <h2>{t('Create your decks')}</h2>

        <div className="my-8">
            <DecksGallery onClick={goToEdit} decks={decks} setDecks={setDecks}/>
        </div>

    </div>)
}
