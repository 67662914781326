import React from 'react';
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { InputSwitch } from "primereact/inputswitch";
import { useHistory } from "react-router-dom";

export function GameConfig () {

    const { t } = useTranslation();
    let history = useHistory();
    const { handleSubmit, watch, reset, control } = useForm();
    console.log('##ABEL## >> GameConfig >>  GameConfig', watch());

    const onSubmit = (formData) => {
        console.log('##ABEL## >> GameConfig >>  onSubmit', formData);
        localStorage.setItem("gameConfig", JSON.stringify(formData))
        history.push("/game");
    }

    return (<div>
        <h2>{t('Configure your game')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
            <label>
                <span className="b-text-label">{t('Enable score')}</span>
            </label>

            <Controller
                name="isCountingScore"
                defaultValue={true}
                control={control}
                render={(props) => <InputSwitch className={"g-primereact-input-switch"}
                                                onChange={e => props.onChange(e.value)}
                                                checked={props.value}/>
                }
            />

            <div className={"d-flex justify-content-end"}>
                <button className={"b-text-start"}
                >{t('Play')}</button>
            </div>
        </form>


    </div>)
}
