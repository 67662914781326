import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCardsByDescription, postCard, putCard } from "../../queries/card.queries";
import { CardsList } from "../CardsList/CardsList";
import { useForm } from "react-hook-form";
import { typesListConst } from "../../consts/types.const";

let indexType = 0;
const defaultValues = {
    type: typesListConst[indexType],
    description: '',
    answer: null
}
export default function CardsSearchForm ({ deckIdCards = [], idDeck, idUser, onAddCard = () => {} }) {
    const { t } = useTranslation();
    const [isShowingAnswerInput, setIsShowingAnswerInput] = useState(false);
    const { register, watch, setValue, handleSubmit } = useForm({
        defaultValues
    })

    const descriptionRef = useRef();

    const [searchTerm, setSearchTerm] = useState('')

    const [cards, setCards] = useState([]);
    const searchCard = async () => {
        const { data: { data: cards } } = await getCardsByDescription(searchTerm);
        setCards(cards)
    }
    const addOrUpdateCard = async (newCard) => {
        const newLocalCard = { ...watch(), user: idUser, decks: [idDeck] }
        if (newCard.id) {

            const { data: { data: card } } = await putCard(newCard.id, { data: { ...newLocalCard, ...newCard.attributes } });
            onAddCard(card)
        } else {
            const { data: { data: card } } = await postCard({ data: newLocalCard });
            onAddCard(card)

        }

        setIsShowingAnswerInput(false);
        // reset(defaultValues);
        setValue("description", "")
        setValue("answer", null)

    }


    const changeType = () => {
        indexType++;
        setValue("type", typesListConst[indexType])

        if (indexType === typesListConst.length + 1) {
            indexType = 0
        }

    }

    const onEnterPress = async (e, wantToShowAnswerInput) => {
        console.log('##ABEL## >> default >>  onEnterPress', e);
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            const formValues = watch();
            if (formValues.description.length > 0) {
                await addOrUpdateCard(watch())
                descriptionRef.current.focus()
            }
        } else if(wantToShowAnswerInput && e.keyCode == 9){
            setIsShowingAnswerInput(true)
        }
    }

    useEffect(() => {
        register({ name: 'type' });
        register(descriptionRef.current, {required: true})
    }, [])

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            await searchCard()
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])
    return (<div>
            <form onSubmit={handleSubmit(addOrUpdateCard)}>
                <textarea className="b-input" required
                          name={"description"}
                          ref={descriptionRef} onKeyDown={(e) => onEnterPress(e,true)}
                          placeholder={t("Search a Card to add it")} onChange={(e) => setSearchTerm(e.target.value)}/>

                {isShowingAnswerInput && <textarea className="mt-4 b-input"
                                                   name={"answer"}
                                                   ref={register()}
                                                   placeholder={t("Add your answer here")} onKeyDown={onEnterPress}/>}
            </form>
            <div>

                <CardsList onClick={changeType}
                           onAnswerClick={() => setIsShowingAnswerInput(true)}
                           cards={[{
                               isNewCard: true,
                               attributes: {
                                   type: watch("type"),
                                   description: watch("description"),
                                   answer: watch("answer") && watch("answer").length > 0 ? watch("answer") : t("Click here to add" +
                                       " answer")
                               }
                           }, ...cards]}
                           cardsAction={[{ onClick: addOrUpdateCard, icon: 'icon-add', show: true }]}
                           deckIdCards={deckIdCards}/>
            </div>
        </div>
    )
}
