import React, { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { PlayerContext } from "../../../../shared/contexts/PlayerContext";


export function SelectRandomUser ({ onDrawCard }) {


    const {
        actualPlayer,

    } = useContext(PlayerContext);
    const { t } = useTranslation()

    return (
        <div>
            <h2 className={"mt-4"}>{t('Turn of')}...</h2>
            <div className="mt-0 mb-12">
                {actualPlayer && <div className="d-flex justify-content-center u-width-100">

                    <div
                        className="my-7 py-7 px-9 d-inline-block b-badge b-badge--auto b-badge--secondary b-badge--square">
                        <span style={{ color: actualPlayer.color }} className="b-icon icon-profile-user"/> <span
                        className="b-text">{actualPlayer.name}</span>
                    </div>
                    <div
                        className="my-7 py-7 px-9 d-inline-block b-badge b-badge--auto b-badge--secondary b-badge--square">
                       <span
                           className="b-text">{actualPlayer.score + " " + t("Points")}</span>
                    </div>
                </div>}
            </div>

            <div>
                <span className="b-text-start b-text-start--fixed" onClick={onDrawCard}>{t("Draw" +
                    " card")}!</span>
            </div>
        </div>
    )
}
