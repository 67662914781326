import React from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import LoginPage from "../pages/LoginPage/LoginPage";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import ProfileEditPage from "../pages/ProfilePage/pages/ProfileEditPage/ProfileEditPage";
import VerifyPage from "../pages/VerifyPage/VerifyPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage/ResetPasswordPage";
import GamePage from "../pages/GamePage/GamePage";
import { PlayersPage } from "../pages/PlayersPage/PlayersPage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './Routes.scss'
import Menu from "./components/Menu/Menu";
import { CardsPage } from "../pages/CardsPage/CardsPage";
import { CardsAddOrEditPage } from "../pages/CardsPage/pages/CardsAddOrEditPage/CardsAddOrEditPage";
import { DecksPage } from "../pages/DecksPage/DecksPage";
import { DecksAddOrEditPage } from "../pages/DecksPage/pages/DecksAddOrEditPage/DecksAddOrEditPage";
import { SelectDeckPage } from "../pages/SelectDeckPage/SelectDeckPage";
import PrivateRoute from "../shared/components/PrivateRoute/PrivateRoute";
import { InfoPage } from "../pages/InfoPage/InfoPage";
import { GameConfig } from "../pages/GameConfig/GameConfig";

export default function Routes () {
    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
            >
                <div className="home">
                    <div className="container">
                        <div className="p-4">
                            <Menu/>
                            <div className="py-5 mb-5">


                                <Switch location={location}>
                                    <Route path="/login">
                                        <LoginPage/>
                                    </Route>
                                    <Route path="/register">
                                        <RegisterPage/>
                                    </Route>
                                    <Route path="/forgot-password">
                                        <ForgotPasswordPage/>
                                    </Route>
                                    <Route path="/reset/:idUser">
                                        <ResetPasswordPage/>
                                    </Route>
                                    <Route path="/profile/edit">
                                        <ProfileEditPage/>
                                    </Route>
                                    <Route path="/profile">
                                        <ProfilePage/>
                                    </Route>
                                    <Route path="/information">
                                        <InfoPage/>
                                    </Route>
                                    <Route path="/verify/:idUser">
                                        <VerifyPage/>
                                    </Route>


                                    <Route path="/players">
                                        <PlayersPage/>
                                    </Route>
                                    <Route path="/select-deck">
                                        <SelectDeckPage/>
                                    </Route>
                                    <Route path="/game-config">
                                        <GameConfig/>
                                    </Route>
                                    <Route path="/game">
                                        <GamePage/>
                                    </Route>


                                    <PrivateRoute path="/decks/add">
                                        <DecksAddOrEditPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/decks/:idDeck/edit">
                                        <DecksAddOrEditPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/decks">
                                        <DecksPage/>
                                    </PrivateRoute>

                                    <PrivateRoute path="/cards/add">
                                        <CardsAddOrEditPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/cards/:idCard/edit">
                                        <CardsAddOrEditPage/>
                                    </PrivateRoute>
                                    <PrivateRoute path="/cards">
                                        <CardsPage/>
                                    </PrivateRoute>


                                    <Route path="/">
                                        <HomePage/>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </TransitionGroup>
    )
}
