import React, { useContext, useEffect, useState } from "react";
import { API } from "../../../../shared/consts/api.const";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JwtContext } from "../../../../shared/contexts/JwtContext";
import { CardsList } from "../../../../shared/components/CardsList/CardsList";
import CardsSearchForm from "../../../../shared/components/CardsSearchForm/CardsSearchForm";
import { getDeck, putDeck } from "../../../../shared/queries/deck.queries";
import { deleteCard } from "../../../../shared/queries/card.queries";
import { confirmDialog } from "primereact/components/confirmdialog/ConfirmDialog";

let deckIdCards = [];
let duplicateCards;

export function DecksAddOrEditPage () {
    const { t } = useTranslation();
    const { idDeck } = useParams();
    const history = useHistory();

    const { register, handleSubmit, reset } = useForm();
    const { user } = useContext(JwtContext)
    const [deck, setDeck] = useState({});
    const [cards, setCards] = useState([]);

    const onSubmit = async (newDeck) => {
        await addDeck(newDeck);
        history.push('/decks');
    }

    const addDeck = async (newDeck) => {
        if (idDeck) {
            newDeck = { ...newDeck, idCards: deckIdCards }
            await API.patch('decks/' + idDeck, newDeck)
        } else {
            newDeck = user.role === 'user' ? { scope: 'personal', ...newDeck } : { scope: 'official', ...newDeck }
            await API.post('decks', { data: { ...newDeck, user: user } })
        }
    }

    const getDeckInfo = async () => {
        if (idDeck) {
            const { data: { data: deck } } = await getDeck(idDeck);
            console.log('##ABEL## >> DecksAddOrEditPage >>  getDeckInfo', deck);
            // const resDeckCards = await API.post('cards/some', { idCards: deck.idCards });
            // deckIdCards = resDeckCards.data.docs;

            // const personalResCards = await API.get('cards?limit=10000&filter[scope]=personal&' +
            //     'filter[idUser]=' + user.id);

            updateAllStates(deck)
        }
    }

    const updateAllStates = (deck) => {
        duplicateCards = deck.attributes.duplicateCards
        const cards = createCountCardProp(deck.attributes.cards.data, duplicateCards);
        reset({ name: deck.attributes.name })
        setDeckAndCards(deck, cards)
    }

    const updateDeckState = (card) => {
        const newCards = [...cards, card];
        setDeckAndCards({ ...deck, cards: { data: newCards } }, newCards)
    }


    const onRemoveCardFromDeck = async (card, isPutDeckNecessary = true) => {

        const cards = deck.attributes.cards.data.filter(localCard => localCard.id !== card.id)
        if (isPutDeckNecessary) {
            const { data: { data: localDeck } } = await putDeck(deck.id, { data: { cards } });
            setDeckAndCards(localDeck, localDeck.attributes.cards.data)
        } else {
            setDeckAndCards({ ...deck, attributes: { cards: { data: { cards } } } }, cards)
        }
    }

    const setDeckAndCards = (deck, cards) => {
        setDeck(deck)
        setCards(cards)
    }

    const onDeleteCard = async (deck) => {
        confirmDialog({
            message: t('You will delete this card forever from all decks'),
            header: t('Are you sure?'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t("Confirm"),
            rejectLabel: t("Cancel"),
            accept: async () => {
                await deleteCard(deck.id);
                await onRemoveCardFromDeck(deck.id, false)
            },
        });


    }

    const changeCountCard = async (card, duplicateCards, isPlus) => {

        const foundIndex = duplicateCards.findIndex(duplicateCard => duplicateCard.card.data && card && duplicateCard.card.data.id === card.id);
        if (foundIndex !== -1) {
            duplicateCards[foundIndex] = {
                ...duplicateCards[foundIndex],
                count: (isPlus ? duplicateCards[foundIndex].count + 1 : duplicateCards[foundIndex].count - 1)
            }
        } else {
            duplicateCards.push({ card: { data: { id: card.id } }, count: 2 })
        }
        const transformedDuplicateCards = duplicateCards.map(duplicateCard => ({
            ...duplicateCard,
            card: duplicateCard.card.data
        }))
        const { data: { data: localDeck } } = await putDeck(deck.id, {
            data: {
                duplicateCards: transformedDuplicateCards
            }
        });
        updateAllStates(localDeck)

    }


    useEffect(getDeckInfo, [])

    return (<div>
        <h2>{t('Edit or add deck cards')}</h2>
        <form>
            < label className="mb-6">
                <h3 className="b-text-label">{t('Deck name')}</h3>
                <input className="b-input" readOnly={!(!idDeck || user.role === 'admin' || user.id === deck.idUser)}
                       type="text"
                       name="name" ref={register({ required: true })}/>
            </label>
        </form>
        {
            (!idDeck || user.role === 'admin' || user.id === deck.idUser) &&
            <button className="mt-8 b-btn" onClick={handleSubmit(onSubmit)}>{t('Save')}</button>

        }


        {
            idDeck && <>
                <h3 className="b-text-label d-flex align-items-center">{t('Deck cards')}{" (" + cards.length + ")"}</h3>
                {/*<Panel className="g-primereact-panel" toggleable collapsed={true} header={t('Filter Cards')}>*/}
                {/*    <CardFilterForm allCards={allCards} setCards={setCards}/>*/}
                {/*</Panel>*/}
                <CardsList
                    showCount={true}
                    cards={cards}
                    cardsAction={[
                        {
                            onClick: (card) => changeCountCard(card, duplicateCards, true),
                            icon: 'b-mini-card--plus',
                            show: true
                        }, {
                            onClick: (card) => changeCountCard(card, duplicateCards, false),
                            icon: 'b-mini-card--minus',
                            show: true
                        },
                        { onClick: onRemoveCardFromDeck, icon: 'icon-cancel', show: true },
                        {
                            onClick: onDeleteCard,
                            icon: 'icon-delete',
                            show: true
                        }]}

                />

            </>
        }

        <div className={"mt-6"}>
            <h3 className="b-text-label d-flex align-items-center">{t('Add new cards')}</h3>

            <CardsSearchForm deckIdCards={deckIdCards} idDeck={deck.id} idUser={user.id} onAddCard={updateDeckState}/>
        </div>


    </div>)
}


const createCountCardProp = (cards, duplicateCards) => {

    for (const card of cards) {
        const foundCard = duplicateCards.find(duplicateCard => duplicateCard.card.data && duplicateCard.card.data && card && duplicateCard.card.data.id === card.id);


        card.count = foundCard ? foundCard.count : 1;
    }

    return cards
}
