import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { DecksGallery } from "../DecksPage/components/DecksGallery/DecksGallery";
import { getDecks } from "../../shared/queries/deck.queries";

export function SelectDeckPage () {

    const { t } = useTranslation();


    const [decks, setDecks] = useState([]);
    const [selectedIdDeck, setSelectedIdDeck] = useState(null);

    const history = useHistory();

    const getAllDecks = async () => {
        try {
            const { data: { data } } = await getDecks();

            setDecks(data);
        } catch (e) {
            setDecks([]);
        }
    }

    const selectDeck = (idDeck) => {
        if (selectedIdDeck === idDeck) {
            localStorage.removeItem('deck');
            setSelectedIdDeck(null);
        } else if (idDeck) {
            localStorage.setItem('deck', idDeck);
            setSelectedIdDeck(idDeck);
        } else {
            localStorage.removeItem('deck');
            setSelectedIdDeck(null);
            history.push('/decks/add')
        }
    }

    useEffect(getAllDecks, [])

    return (
        <div>
            <h1>{t('Select deck')}</h1>
            <DecksGallery onClick={selectDeck} selectedIdDeck={selectedIdDeck} decks={decks} setDecks={setDecks}/>
            <div className={"d-flex justify-content-end"}>
                <Link className={selectedIdDeck ? "b-text-start" : "b-text-start u-disabled"}
                      to="/game-config">{t('Config Game')}</Link>
            </div>
        </div>
    )
}
