import React, { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../../../shared/consts/api.const";
import { categoriesConst } from "../../../../shared/consts/categories.const";
import { JwtContext } from "../../../../shared/contexts/JwtContext";
import { updateGlobalUser } from "../../../ProfilePage/pages/ProfileEditPage/ProfileEditPage";

export function CardsAddOrEditPage () {
    const { t } = useTranslation();
    const { idCard } = useParams();
    const history = useHistory();

    const { register, handleSubmit, watch, reset } = useForm();
    const selectedCategories = watch('categories');

    const { user, setUser } = useContext(JwtContext)

    const submit = async (newCard) => {
        if (user.role === 'user') {
            await addPersonalCard(newCard);
        } else if (user.role === 'admin') {
            await addOfficialCard(newCard);
        }

        if (idCard) {
            history.push('/cards');
        } else {
            reset();
        }
    }

    const addOfficialCard = async (newCard) => {
        if (idCard) {
            await API.patch('cards/' + idCard, newCard)
        } else {
            await API.post('cards', newCard)
        }
    }

    const addPersonalCard = async (newCard) => {
        const userCopy = { ...user };
        if (idCard) {
            const indexEditingCard = userCopy.cards.findIndex(card => card.id === idCard);
            userCopy.cards[indexEditingCard] = newCard;
        } else {
            userCopy.cards.push(newCard);
        }
        const { newUser } = await API.patch('profile', userCopy)
        await updateGlobalUser(newUser, setUser)
    }

    const getCardInfo = async () => {
        if (idCard) {
            const res = await API.get('cards/' + idCard);
            reset(res.data)
        }
    }

    useEffect(getCardInfo, [])

    return (<>
            <h2>{t('Create a new card')}</h2>
            <form onSubmit={handleSubmit(submit)}>

                <label className="mb-6">
                    <span className="b-text-label">{t('Card description')}</span>
                    <textarea className="b-input" type="text" name="description.es" ref={register({ required: true })}/>
                </label>


                <fieldset className="my-6">
                    <legend className="b-text-label">{t('Select categories')}</legend>
                    {categoriesConst.es.map((category, i) =>
                        <div>
                            <label key={i} className="b-checkbox">
                                <span>{category}</span>
                                <input className="b-checkbox__box" type="checkbox" name="categories" value={category}
                                       ref={register({ required: true })}/>
                                <span className="b-checkbox__check"
                                      style={{ backgroundColor: selectedCategories && selectedCategories.includes(category) ? 'var(--color-' + categoriesConst.en[i].toLowerCase() + ')' : 'transparent' }}/>
                            </label>
                        </div>)}
                </fieldset>

                <fieldset className="my-6">
                    <legend className="b-text-label">{t('Select type')}</legend>

                    <div>
                        <label className="b-radio">
                            <span>{t('Truth')}</span>
                            <input className="b-radio__box" type="radio" ref={register} name="type" value="truth"/>
                            <span className="b-radio__check icon-question"/>
                        </label>
                    </div>
                    <div>
                        <label className="b-radio">
                            <span>{t('Dare')}</span>
                            <input className="b-radio__box" type="radio" ref={register} name="type" value="dare"/>
                            <span className="b-radio__check icon-exclamation"/>
                        </label>
                    </div>
                </fieldset>


                <input className="b-btn" type="submit" value={t('Add')}/>
            </form>
        </>
    )
}
