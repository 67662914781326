import React from "react";
import { useTranslation } from "react-i18next";

export function InfoPage () {

    const { t } = useTranslation();
    return (<div>
        <ul>
            <li className="b-text my-7">1. {t('Start game')}.</li>
            <li className="b-text my-7">2. {t('Add players')}.</li>
            <li className="b-text my-7">3. {t('Select your deck')}. {t('Every deck is a custom experience')}. {t('You can create an account to add new cards or custom you personal decks')}.
            </li>
            <li className="b-text my-7">5. {t('Play')}. {t('Click random player. Player need to pick a card. Do what' +
                ' the' +
                ' card' +
                ' say')}</li>
        </ul>
    </div>)
}
