import qs from "qs";
import { API } from "../consts/api.const";


export const getCards = (query) => {
    query = query ? query : qs.stringify(
        {
            populate: "*",
        },
        {
            encodeValuesOnly: true, // prettify URL
        }
    );
    return API.get(`cards?${query}`)
}

export const getCardsByDeckId = (idDeck, query) => {
    query = query ? query : qs.stringify(
        {
            filters: {
                decks: {
                    id:  idDeck
                }
            },
        }, {
            encodeValuesOnly: true, // prettify URL
        }
    );
    return API.get(`cards?${query}`)
}


export const getCardsByDescription = (description, query) => {
    query = query ? query : qs.stringify(
        {
            filters: {
                description: {
                    $containsi: description,
                }
            }
        },
    );
    return API.get(`cards?${query}`)
}


export const postCard = (card) => {
    return API.post(`cards`, card)
}

export const putCard = (idCard, card) => {
    return API.put(`cards/` + idCard, card)
}
export const deleteCard = (idCard) => {
    return API.delete(`cards/${idCard}`)
}
