import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { JwtContext } from "../../../../shared/contexts/JwtContext";
import { API } from "../../../../shared/consts/api.const";
import { useHistory } from "react-router-dom";

export default function ProfileEditPage () {
    const { user, setUser } = useContext(JwtContext);
    const { register, handleSubmit } = useForm()
    const { t } = useTranslation();

    const history = useHistory();

    const editUser = async (user) => {
        const userCopy = { ...user };
        if (userCopy.password <= 0) {
            delete userCopy.password;
        }

        await updateGlobalUser(userCopy, setUser)

        history.push('/profile')
    }

    return (
        <form onSubmit={handleSubmit(editUser)}>
            <div className="py-4">
                <label htmlFor="name">{t('Name')}</label>
                <input className="b-input" id="name" type="text" name="name" defaultValue={user.name} ref={register}/>
            </div>
            <div className="py-4">
                <label htmlFor="email">{t('Email')}</label>
                <input className="b-input" id="email" type="text" name="email" defaultValue={user.email}
                       ref={register}/>
            </div>
            {!user.facebookId && <div className="py-4">
                <label htmlFor="password">{t('Password')}</label>
                <input className="b-input" id="password" type="password" name="password" ref={register}/>
            </div>}
            <button className="b-btn b-btn--sec mt-4">{t('Edit')}</button>
        </form>
    )
}

export const updateGlobalUser = async (newUser, setUser) => {
    const { data } = await API.patch('profile', newUser)
    setUser(data);
    document.cookie = 'user=' + JSON.stringify(data);
}
