import React, { useContext } from "react";
import { DeckItem } from "../DeckItem/DeckItem";
import { useTranslation } from "react-i18next";
import { API } from "../../../../shared/consts/api.const";
import { spliceStateVar } from "../../../../shared/utils/spliceStateVarUtil";
import { JwtContext } from "../../../../shared/contexts/JwtContext";

export function DecksGallery ({ decks, setDecks, onClick, selectedIdDeck }) {
    const { t } = useTranslation()
    const { user } = useContext(JwtContext)

    const deleteDeck = async (idDeck, i) => {
        await API.delete('/decks/' + idDeck);
        spliceStateVar(decks, setDecks, i);
    }
console.log('##ABEL## >> DecksGallery >>  DecksGallery', decks);
    return (<div>
        <div className="row">
            <div className="mb-8 col-6 col-sm-6 col-lg-4 d-flex flex-column align-items-center">
                <DeckItem onClick={() => onClick()} iconClass="icon-add"/>
                <h4>{t('Add new deck')}</h4>
            </div>

            {decks.map((deck, i) => <div key={i} className="mb-8 col-6 col-sm-6 col-lg-4 d-flex flex-column align-items-center">
                <DeckItem className={deck.id === selectedIdDeck ? 'c-deck--selected' : ''} key={i}
                          onClick={() => onClick(deck.id)}
                          iconClass={deck.attributes.icon || "icon-exclamation"}/>
                <div className="d-flex align-items-center"><h4>{deck.attributes.name}</h4>
                    {(user.role === 'admin' || user.id === deck.attributes.user.data.id) && <span onClick={() => deleteDeck(deck.id, i)} className="ml-5 u-cursor-pointer icon-delete"/>}
                </div>

            </div>)}


        </div>
    </div>)
}
