import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { JwtContext } from "../../shared/contexts/JwtContext";
import ChangeLanguage from "./components/ChangeLanguage/ChangeLanguage";
import SignOutButton from "./components/SignOutButton/SignOutButton";

export default function ProfilePage () {

    const { jwt } = useContext(JwtContext);
    const { t } = useTranslation();

    return (
        <div>
            <SignOutButton/>

            <div className="row">
                <div className="col-12 col-lg-6">
                    {!jwt && <Link to="/register" className="my-4 b-btn b-btn--third">{t('Sign in')}</Link>}
                </div>
                <div className="col-12 col-lg-6">
                    {!jwt && <Link to="/login" className="my-4 b-btn b-btn--sec">{t('Log in')}</Link>}
                </div>
            </div>
            <div>

                <ChangeLanguage/>
                <div className="my-4 d-flex justify-content-center">
                    <a href="http://codictados.com/politica-de-privacidad" rel="noreferrer"
                       target="_blank">{t('Privacy' +
                        ' Policy')}</a>
                </div>
            </div>
        </div>
    )
}
