import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "../../shared/consts/api.const";
import { JwtContext } from "../../shared/contexts/JwtContext";
import { CardsList } from "../../shared/components/CardsList/CardsList";

export function CardsPage () {
    const { t } = useTranslation();
    const [personalCards, setPersonalCards] = useState([]);
    const [officialCards, setOfficialCards] = useState([]);
    const { user } = useContext(JwtContext)

    const history = useHistory();

    const getCards = async () => {
        const officialResCards = await API.get('cards?limit=10000&filter[scope]=official');
        const personalResCards = await API.get('cards?limit=10000&filter[scope]=personal&filter[idUser]=' + user.id);
        setOfficialCards(officialResCards.data.docs);
        setPersonalCards(personalResCards.data.docs);
    }

    const removeCard = async (idCard) => {
        await API.delete('cards/' + idCard);
    }

    const editCard = async (idCard) => {
        history.push('cards/' + idCard + '/edit')
    }

    useEffect(getCards, [])
    return (<div>
        {user && user.cards && user.role === 'user' &&
        <div>
            <h2>{t('Personal cards')}</h2>
            <Link className="b-btn" to="/cards/add">{t('Add new card')}</Link>

            <CardsList cards={personalCards}
                       cardsAction={[{ onClick: editCard, iconClass: 'icon-pencil', show: user.role === 'admin' }, {
                           onClick: removeCard,
                           iconClass: 'icon-delete',
                           show: user.role === 'admin'
                       }]}/>
        </div>
        }

        <h2>{t('Official cards')}</h2>
        {user.role === 'admin' && <Link className="b-btn" to="/cards/add">{t('Add new card')}</Link>}
        <div className="my-8">
            <CardsList cards={officialCards}
                       cardsAction={[{ onClick: editCard, iconClass: 'icon-pencil', show: user.role === 'admin' }, {
                           onClick: removeCard,
                           iconClass: 'icon-delete',
                           show: user.role === 'admin'
                       }]}/>
        </div>
    </div>)
}
