import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";

import { JwtContext } from './shared/contexts/JwtContext';
import { getCookieUtil } from "./shared/utils/getCookieUtil";
import { ToastContext } from "./shared/contexts/ToastContenxt";
import ErrorHandler from "./core/components/ErrorHandler/ErrorHandler";
import Routes from "./core/Routes";

function App () {

    const [jwt, setJwt] = useState(getCookieUtil('token') || null);

    const unParsedUser = getCookieUtil('user');
    const [user, setUser] = useState(unParsedUser.length > 0 ? JSON.parse(unParsedUser) : {});

    const [toast, setToast] = useState(null);

    return (
        <JwtContext.Provider value={{ jwt, setJwt, user, setUser }}>
            <Router>
                <ToastContext.Provider value={{ toast, setToast }}>
                    <ErrorHandler/>
                    <Routes/>
                </ToastContext.Provider>
            </Router>
        </JwtContext.Provider>
    );
}

export default App;
